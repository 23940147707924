.spotify-playlist-container {
    box-sizing: border-box; /* added */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem;
    background: linear-gradient(180deg, #EA2B1F 0%, #EDAE49 48.55%, #F9DF74 100%) no-repeat fixed;
}

.spotify-playlist-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    margin: 2rem 0 1rem;
    color: #fff;
    text-align: center;
}

.spotify-iframe {
    width: 100%;
    max-width: 800px;
    height: 380px;
    margin: 2rem auto; /* updated */
}

@media (max-width: 768px) {
    .spotify-playlist-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .spotify-playlist-title {
        font-size: 3rem;
        text-align: center;
    }

    .spotify-iframe {
        max-width: 500px;
    }
}
